// @ts-nocheck
import React, {
  useState,
  useCallback,
  useContext,
  useRef,
  useEffect
} from "react";
import { TripsContext } from "../../../../context/Trips";
import { getLastWeekDay } from "../../../../utils/dates";
import DateRange from "../../../../components/DateRange";
import Select from "../../../../components/Select";
import styles from "./styles.module.css";
import {
  getTripStatus
} from "../../../../utils/translateEnums";

import {
  Row,
  Col,
  Card,
  CardBody,
  Input,
  InputGroup,
  Button
} from "reactstrap";
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";
//@ts-ignore
import debounce from "lodash.debounce";

import moment from "moment";
import { formatNumber } from "../../../../utils/masks";

interface Props {
  updateParentFilter(f: any): void;
  monitoring: boolean;
}

const Filter = ({ updateParentFilter, monitoring }: Props) => {
  const buttonRef = useRef(null);

  const [filter, setFilter] = useState({
    unique_id: "",
    city_id: "",
    trip_status: "",
    user_first_name: "",
    provider_first_name: "",
    start_date: getLastWeekDay(),
    end_date: new Date(),
    page_size: 25
  });

  const {
    state: { trips, cities },
    action: { getTrips, fetchCities, getTripsMonitoring }
  } = useContext(TripsContext);

  const datesFilter = (dates: Date[]) => {
    setFilter(prev => ({
      ...prev,
      start_date: dates[0],
      end_date: dates[1]
    }));
  };

  const applyFilter = () => {
    if (filter.start_date && filter.end_date) {
      if (monitoring) {
        getTripsMonitoring(filter)
      } else {
        getTrips(filter);
      }
      updateParentFilter(filter);
    } else toast.error("Adicione um campo correto para as datas");
  };

  const onChange = useCallback(e => {
    const { value, name } = e.target;
    setFilter(prev => ({ ...prev, [name]: value }));
  }, []);

  const handleCityChange = useCallback(value => {
    setFilter(prev => ({ ...prev, city_id: value }));
  }, []);

  useEffect(() => {
    fetchCities();
    const handleOnKeyDown = debounce((e: React.KeyboardEvent) => {
      if (e.which === 13 && buttonRef.current) {
        e.preventDefault();
        //@ts-ignore
        buttonRef.current.onClick();
      }
    }, 600)
    window.addEventListener("keydown", e => handleOnKeyDown(e));
  }, []);

  return (
    <>
      <Row className="mb-4">
        {!monitoring ?
          <Col md={3} sm={6}>
            <label>Data</label>
            <InputGroup className={styles.filterInput}>
              <DateRange
                value={[filter.start_date, filter.end_date]}
                onChange={datesFilter}
              />
            </InputGroup>
          </Col> : ''}
        <Col md={1} sm={4}>
          <label>ID</label>
          <Input
            bsSize="sm"
            placeholder="ID entrega"
            name="unique_id"
            className={styles.filterInput}
            onChange={onChange}
          />
        </Col>
        <Col md={3} sm={4}>
          <label>Cidade</label>
          <Select
            name="city_id"
            value={filter.city_id}
            options={cities.map(c => {
              return { label: c.cityname, value: c._id }
            })}
            onChange={handleCityChange}
          />
        </Col>
        <Col md={2} sm={4}>
          <label>Empresa</label>
          <Input
            bsSize="sm"
            placeholder="Nome da loja..."
            name="user_first_name"
            className={styles.filterInput}
            onChange={onChange}
          />
        </Col>
        <Col md={2} sm={4}>
          <label>Entregador</label>
          <Input
            bsSize="sm"
            placeholder="Nome do entregador..."
            name="provider_first_name"
            className={styles.filterInput}
            onChange={onChange}
          />
        </Col>
        <Col md={monitoring ? 4 : 1} style={{ textAlign: "right" }}>
          <Button
            style={{ marginTop: '32px', marginBottom: '0px' }}
            ref={buttonRef}
            onClick={applyFilter}
            className="btn btn-primary"
          >
            Filtrar
          </Button>
        </Col>
      </Row>
    </>
  );
};

const StatusOptions = [
  { label: "Todos", value: "99" },
  { label: "Solicitado", value: '0' },
  { label: "Aceita", value: '1' },
  { label: "A camiho da loja", value: '2' },
  { label: "Cancelada - Loja", value: '32' },
  { label: "Cancelada - Entregador", value: '31' },
  { label: "Chegou na loja", value: '4' },
  { label: "Entregando", value: '6' },
  { label: "Retornando a loja", value: '8' },
  { label: "Concluída", value: '9' }
];

const transformData = (results: any[]) => {
  const exportTable: any[] = [];
  results.map(trip => {
    exportTable.push([
      moment(trip.user_create_time).format("DD/MM/YYYY"), //data
      trip.unique_id, //id
      trip.user.unique_id, // lojista_id
      trip.user.first_name + ' ' + trip.user.last_name, // lojista
      trip.provider.unique_id, // entregador_id
      trip.provider.first_name + ' ' + trip.provider.last_name, // entregador
      formatNumber(trip.total_for_company), // receita
      formatNumber(trip.total), // repasse
      getTripStatus(trip.status), // status
      trip.is_trip_cancelled, // cancelada
    ])
  });
  return exportTable;
};

export default Filter;
