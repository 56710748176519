// @ts-nocheck
import React from "react";
import { toNumber } from "../utils/masks";
import { AuthContext } from "./Auth";
import { toast } from "react-toastify";
import moment from "moment";

interface TripsContext {
  state: State;
  action: {
    getTrips(filter: any, page?: number): void;
    getTripsMonitoring(filter: any, page?: number): void;
    getTripDetails(tripId: string): void;
    cancelTrip(tripId: string): void;
    getCompanies(): void;
    fetchCities(): void;
    getTypes(): void;
    createTripOutside(data: any): void;
    changeDirectProviderId(data: any): void;
    directTripToProvider(): void;
  };
}

export const TripsContext = React.createContext({} as TripsContext);

interface State {
  cities: any[];
  trips: any[];
  tripsPagination: any;
  isTripsLoaded: boolean;
  selectedTrip: any;
  isSelectedTripLoaded: boolean;
  companies: any[];
  services: any[];
  isCreateOutsideTripSuccessful: boolean;
  direct_provider_id: any;
  isDirecting: boolean;
}

class TripsProvider extends React.PureComponent<{}, State> {
  static contextType = AuthContext;

  constructor(props: any) {
    super(props);

    this.state = {
      cities: [],
      trips: [],
      tripsPagination: {},
      isTripsLoaded: false,
      selectedTrip: {},
      isSelectedTripLoaded: false,
      companies: [],
      services: [],
      isCreateOutsideTripSuccessful: false,
      direct_provider_id: "",
      isDirecting: false
    };
  }

  fetchCities = () => {
    const { request } = this.context.action

    this.setState({
      cities: []
    }, async () => {
      try {
        const response = await request('GET', 'admin/v1/cities')

        if (response.data.success)
          this.setState({ cities: response.data.data })
        else throw new Error('Erro ao buscar as cidades.')

      } catch (err) {
        this.setState({
          cities: []
        }, toast.error.bind(null, err.message))
      }
    })
  }

  changeDirectProviderId = (v:any) => {
    console.log(v);
    this.setState({direct_provider_id: v});
  }

  directTripToProvider = async () => {
    const { request } = this.context.action;
    const url = `admin/v1/trips/${this.state.selectedTrip._id}/direct`;
    try {
      console.log(url, {provider_unique_id: this.state.direct_provider_id});
      this.setState({isDirecting: true});
      const response = await request("POST", url, {provider_unique_id: this.state.direct_provider_id});
      const { success, data } = response.data;
      
      if (success){
        toast.success("Direcionamento realizado com sucesso.");
      }
    } catch (error) {

    }
  };

  getTrips = async (filter?: any, page?: number) => {
    const { request } = this.context.action;
    const url = "admin/v1/trips";
    const data: any = {
      ...filter,
      start_date: moment(filter.start_date).format("YYYYMMDD"),
      end_date: moment(filter.end_date).format("YYYYMMDD")
    };
    data["page_size"] = filter.page_size;
    data["page"] = !!page ? page : 1;

    if (!!filter.unique_id) {
      data["unique_id"] =
        filter.unique_id === "" ? "" : Number(filter.unique_id);
    }

    this.setState(
      {
        isTripsLoaded: false
      },
      async () => {
        try {
          const response = await request("POST", url, data);
          if (response.data.success) {
            const { trips, count, current_page, pages } = response.data.data;
            const tripsPagination = {
              count,
              current_page,
              pages
            };

            this.setState({
              trips,
              isTripsLoaded: true,
              tripsPagination
            });
          } else throw new Error("Erro ao buscar as viagens");
        } catch (error) {
          const tripsPagination = {
            count: 0,
            current_page: 1,
            pages: 1
          };
          this.setState(
            {
              trips: [],
              isTripsLoaded: true,
              tripsPagination
            },
            toast.error.bind(this, error.message)
          );
        }
      }
    );
  };

  getTripsMonitoring = async (filter?: any, page?: number) => {
    const { request } = this.context.action;
    const url = "admin/v1/trips";
    const today = moment().format("YYYY-MM-DD");
    const data: any = {
      ...filter,
      start_date: moment().subtract(12,"h").format("YYYY-MM-DD H:m"),
      end_date: moment().add(6,"h").format("YYYY-MM-DD H:m:s"),
      monitoring: true
    };

    data["page_size"] = 30;
    data["page"] = !!page ? page : 1;

    if (!!filter.unique_id) {
      data["unique_id"] =
        filter.unique_id === "" ? "" : Number(filter.unique_id);
    }

    this.setState(
      {
        isTripsLoaded: false
      },
      async () => {
        try {
          const response = await request("POST", url, data);
          if (response.data.success) {
            const { trips, count, current_page, pages } = response.data.data;
            console.log(trips);

            const tripsPagination = {
              count,
              current_page,
              pages
            };

            this.setState({
              trips,
              isTripsLoaded: true,
              tripsPagination
            });
          } else throw new Error("Erro ao buscar as viagens");
        } catch (error) {
          const tripsPagination = {
            count: 0,
            current_page: 1,
            pages: 1
          };
          this.setState(
            {
              trips: [],
              isTripsLoaded: true,
              tripsPagination
            },
            toast.error.bind(this, error.message)
          );
        }
      }
    );
  };

  getTripDetails = async (tripId: string) => {
    const { request } = this.context.action;
    const url = `admin/v1/trips/${tripId}`;

    this.setState(
      {
        isSelectedTripLoaded: false
      },
      async () => {
        try {
          const response = await request("GET", url);
          // console.log(response.data.data)
          console.log(response.data.data);
          if (response.data.success)
            this.setState({
              selectedTrip: response.data.data,
              isSelectedTripLoaded: true
            });
          else throw new Error("Falha ao buscar informações sobre a viagem");
        } catch (err) {
          this.setState(
            {
              selectedTrip: null,
              isSelectedTripLoaded: true
            },
            toast.error.bind(this, err.message)
          );
        }
      }
    );
  };

  cancelTrip = async (tripId: string) => {
    const { request } = this.context.action;
    const url = `admin/v1/trips/${tripId}`;

    this.setState(
      {
        isSelectedTripLoaded: false
      },
      async () => {
        try {
          const response = await request("DELETE", url);
          // console.log(response.data.data)
          if (response.data.success) {
            this.setState({
              selectedTrip: response.data.data,
              isSelectedTripLoaded: true
            });

            toast.success("Corrida cancelada com sucesso");
          } else throw new Error("Falha ao cancelar a corrida");
        } catch (err) {
          this.setState(
            {
              isSelectedTripLoaded: true
            },
            toast.error.bind(this, err.message)
          );
        }
      }
    );
  };

  getCompanies = () => {
    const { request } = this.context.action;
    const url = "admin/v1/trip_outside/companies";

    this.setState(
      {
        companies: []
      },
      async () => {
        try {
          const response = await request("GET", url);
          const { success, data } = response.data;
          if (success)
            this.setState({
              companies: data.map((c: any) => ({
                label: c.name,
                value: c.cost_center_id
              }))
            });
        } catch (error) {
          this.setState({ companies: [] });
        }
      }
    );
  };

  getTypes = () => {
    const { request } = this.context.action;
    const url = "admin/v1/trip_outside/types";

    this.setState(
      {
        services: []
      },
      async () => {
        try {
          const response = await request("GET", url);
          const { success, data } = response.data;
          if (success)
            this.setState({
              services: data.map((s: any) => ({
                label: s.name,
                value: s.type_id
              }))
            });
        } catch (error) {
          this.setState({ services: [] });
        }
      }
    );
  };

  createTripOutside = (data: any) => {
    const { request } = this.context.action;
    const url = "admin/v1/trip_outside";

    this.setState({
      isCreateOutsideTripSuccessful: false
    }, async () => {
      try {
        const response = await request("POST", url, data);
        if (response.data.success)
          this.setState({ isCreateOutsideTripSuccessful: true },
            toast.success.bind(null, "Corrida cadastrada com sucesso"));
      } catch (error) {
        toast.error("Erro ao cadastrar corrida");
      }
    })
  };

  render() {
    const { executeOnlyIfAuthenticated } = this.context.action;

    const value = {
      state: { ...this.state },
      action: {
        getTrips: executeOnlyIfAuthenticated(this.getTrips),
        getTripsMonitoring: executeOnlyIfAuthenticated(this.getTripsMonitoring),
        getTripDetails: executeOnlyIfAuthenticated(this.getTripDetails),
        cancelTrip: executeOnlyIfAuthenticated(this.cancelTrip),
        getCompanies: executeOnlyIfAuthenticated(this.getCompanies),
        fetchCities: executeOnlyIfAuthenticated(this.fetchCities),
        getTypes: executeOnlyIfAuthenticated(this.getTypes),
        createTripOutside: executeOnlyIfAuthenticated(this.createTripOutside),
        changeDirectProviderId: executeOnlyIfAuthenticated(this.changeDirectProviderId),
        directTripToProvider: executeOnlyIfAuthenticated(this.directTripToProvider)
      }
    };

    return (
      <TripsContext.Provider value={value}>
        {this.props.children}
      </TripsContext.Provider>
    );
  }
}

export default TripsProvider;
